import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

window.Swiper = Swiper;

const swiper = new Swiper('.swiper', {
  slidesPerView: 4,
  spaceBetween: 40,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  autoplay: {
    delay: 5000,
  },
  breakpoints: {
    '@0.00': {
      slidesPerView: 2,
    },
    '@0.75': {
      slidesPerView: 3,
    },
    '@1.00': {
      slidesPerView: 4,
    },
  },
});
